import React, { useEffect, useState } from "react";
import "../css/wb_mis_report.css";
import Import from "../Assets/images/import.png";
import Export from "../Assets/images/export.png";
import excelIcon from "../Assets/images/excel.png";
import Search from "../Assets/images/search.png";
import { apiCall } from "../services/authServieces"; // Assuming you have this service
import CustomDateRangePicker from "../components/CustomDatePicker";
import { useUIContext } from "../context";
import { formatDate } from "../utils/helper";
import { ClipLoader } from "react-spinners";
import { subMonths } from "date-fns"; // Import for date manipulation

const WbMisReport = () => {
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [showPagi, setShowPagi] = useState(false);
  const itemsPerPage = 10;
  const { dateRanges } = useUIContext();

  // Get today's date and calculate two months ago
  const today = new Date();
  const twoMonthsAgo = subMonths(today, 2); // Subtract two months from today

  // Function to check if the date is within the last two months
  const isDateWithinLastTwoMonths = (date) => {
    const reportDate = new Date(date); // Ensure the date is in Date format
    return reportDate >= twoMonthsAgo && reportDate <= today;
  };

  const fetchData = async (page) => {
    try {
      setLoading(true);
      const dates = dateRanges.one.map(date => {
        const localDate = new Date(date);
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JS
        const day = String(localDate.getDate()).padStart(2, '0');
        
        return `${year}-${month}-${day}`; // Format as YYYY-MM-DD
      });
      const params = new URLSearchParams({
        fromDate: dates[0] || undefined,
        toDate: dates[1] || undefined,
        page: page,
        limit: itemsPerPage,
      });

      if(dates.length){
        const res = await apiCall({
          endpoint: `report/getMisReportData?${params.toString()}`,
          method: "GET",
        });
        const transformedData = res.data.map(item => ({
          formattedDate: formatDate(item.date),
          date: item.date,
          values: [item.total, item.delivered, item.failed, item.hsm_seen, item.session_send, item.session_delivered, item.session_failed, item.session_seen],
          imageSrc: Import,
        }));
  
        setReportData(transformedData);
        setTotalPages(Math.ceil(res.total / itemsPerPage));
        setShowPagi(res.total > itemsPerPage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData(page);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchData(1);
  };

  const createPagination = () => {
    const pagesToShow = 1;
    let pages = [];

    if (totalPages > 1) {
      pages.push(1);
      if (currentPage > pagesToShow + 2) pages.push(null);

      for (let i = Math.max(2, currentPage - pagesToShow); i <= Math.min(totalPages - 1, currentPage + pagesToShow); i++) {
        pages.push(i);
      }

      if (currentPage < totalPages - pagesToShow - 1) pages.push(null);
      pages.push(totalPages);
    }

    return pages;
  };

  const handleExport = async () => {
    try{
      setLoading(true);
      const dates = dateRanges.one.map(date => {
        const localDate = new Date(date);
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JS
        const day = String(localDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`; // Format as YYYY-MM-DD
      });

      let fDate = dates[0] || undefined;
      let tDate = dates[1] || undefined;

      const headers = ['Sent Date', 'HSM Send', 'HSM Delivered', 'HSM Failed', 'HSM Seen', 'Session Send', 'Session Delivered', 'Session Failed', 'Session Seen'];
      const csvRows = [];

      csvRows.push(headers.join(','));

      const res = await apiCall({
        endpoint: `report/export/date?fDate=${fDate}&tDate=${tDate}`,
        method: "GET",
      });

      console.log(res);

      const escapeCSVValue = (value) => {
        if (typeof value === 'string') {
          return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
      };
  
      res.data.forEach((item) => {
        const row = [
          escapeCSVValue(item.date),
          escapeCSVValue(item.total),
          escapeCSVValue(item.delivered),
          escapeCSVValue(item.failed),
          escapeCSVValue(item.hsm_seen),
          escapeCSVValue(item.session_send),
          escapeCSVValue(item.session_delivered),
          escapeCSVValue(item.session_failed),
          escapeCSVValue(item.session_seen),
        ];
        csvRows.push(row.join(','));
      });
  
      const csvString = csvRows.join('\n');
      const csvWithBom = "\uFEFF" + csvString;
      const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'mis_report.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }finally{
      setLoading(false);
    }
  };

  const handleDownload = async (date) => {
    try {
      setLoading(true);
      const headers = ['User Name', 'Dealer Code', 'Mobile', 'Message', 'Message Type','Message Category', 'Status', 'Date', 'Error Code'];
      const csvRows = [];
  
      csvRows.push(headers.join(','));
      
      const res = await apiCall({
        endpoint: `report/download/date?date=${date}`,
        method: "GET",
      });
  
      const escapeCSVValue = (value) => {
        if (typeof value === 'string') {
          return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
      };
  
      res.data.forEach((item) => {
        const row = [
          escapeCSVValue(item.created_by),
          "", 
          escapeCSVValue(item.sender_mobile),
          escapeCSVValue(item.msg),
          escapeCSVValue(item.msg_type),
          escapeCSVValue(item.temp_category),
          escapeCSVValue(item.delivery_status),
          escapeCSVValue(item.send_date),
          escapeCSVValue(item.source)
        ];
        csvRows.push(row.join(','));
      });
  
      const csvString = csvRows.join('\n');
      const csvWithBom = "\uFEFF" + csvString;
      const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'mis_report.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
    } finally{
      setLoading(false);
    }
  };

  return (
    <>
      <div className="Mis_report_contian">
        <h4 className="Head_title MisReportTitle"><div className="DateRangeContain"><CustomDateRangePicker rangeKey="one" /><button type="button" className="SearchDateRange" onClick={handleSearch}><img src={Search} alt="Search" /></button></div>
              MIS Report 
          <button type="button" className="ExcelIconContain" onClick={handleExport}>
            <img src={excelIcon} alt="excelIcon" className="ExcelIcon"/> 
          </button></h4>
          <div className="marquee-container">
  <div className="marquee-text">*Please note: Data download is available only for the last two months.</div>
</div>

        <div className="Mis_report_Card122">
          <div className="table_contain tMisReport">
            <table className="Table" id="Table">
              <thead>
                <tr>
                  <th>Sent Date</th>
                  <th>HSM Send</th>
                  <th>HSM Delivered</th>
                  <th>HSM Failed</th>
                  <th>HSM Seen</th>
                  <th>Session Send</th>
                  <th>Session Delivered</th>
                  <th>Session Failed</th>
                  <th>Session Seen</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
              {reportData.length === 0 ? (
                <tr>
                  <td colSpan="12" className="tableDataCenterPosition">
                    No data available
                  </td>
                </tr>
              ) : (
                reportData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.formattedDate}</td>
                    {item.values.map((value, i) => (
                      <td key={i}>{value}</td>
                    ))}
                    <td className="download_icon">
                      {/* Show the download button only if the date is within the last two months */}
                      {isDateWithinLastTwoMonths(item.date) && (
                        <button>
                          <img src={item.imageSrc} alt="Import" onClick={() => handleDownload(item.date)} />
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              )}
              </tbody>
            </table>
          </div>
        </div>

        {showPagi && (
          <div className="pagination">
            {createPagination().map((page, index) => (
              page === null ? (
                <span key={index} className="ellipsis">...</span>
              ) : (
                <button
                  key={index}
                  className={`page-button ${currentPage === page ? 'active' : ''}`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              )
            ))}
          </div>
        )}
      </div>
      {loading && (
        <div className="spinnerOverlay">
          <ClipLoader loading={loading} size={50} />
        </div>
      )}
    </>
  );
};

export default WbMisReport;
