
import { React, useState, useEffect, useRef } from "react";
import Sidebar from "../sidebar/sidebar";
import { Navigate } from "react-router-dom";
import "./style.css";
import "./Header.css";
import DotsIcon from "../../Assets/images/dots.png";
import MenuBar from "../../Assets/images/menu_bar.png";
import walletIcon from "../../Assets/images/wallet.png";
import rupeeIcon from "../../Assets/images/rupee.png";
import { Link } from "react-router-dom";
import { useUIContext } from "../../context/index";
import { apiCall } from "../../services/authServieces";
import { Me } from "../../services/authServieces";
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import LogoutIcon from '@mui/icons-material/Logout';
import Dollarwallet from "../../Assets/images/dollarwallet.png"
import Eurowallet from "../../Assets/images/eurowallet.png"

import euroIcon from '../../Assets/images/euro.png'
import dollarIcon from '../../Assets/images/dollar-sign.png'


// ... other imports

const Header = () => {
  const [redirectLogin, redirectToLogin] = useState(false);
  const [balance, setBalance] = useState('0');
  const [Currency_type, setCurrency_type] = useState('');
  const [profile, setProfile] = useState({ image: "" });
  const { resetContext } = useUIContext();

  const[totallimit,settotallimit]=useState('0');


  
  const {
    isSidebarCollapsed,
    setIsSidebarCollapsed,
    isUserDropDown,
    setIsUserDropDown,
    userType,
  } = useUIContext();
  
  const dropdownRef = useRef(null); // Create a ref for the dropdown

  useEffect(() => {
    Balancefunc();

    fetchUserlimitdata();
    
    const fetchData = async () => {
      try {
        const data = await Me(); 
        
        if (data && data.success) {
          setProfile({
            image: `${data.data.profile_url}`, 
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    
    fetchData();
    
    // Close dropdown on click outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsUserDropDown(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const Balancefunc = async () => {
    try {
      const response = await apiCall({ endpoint: 'api/wp_balance_header', method: 'get' });
      if (response.data) {

        let finalval='';

        let balmaster=response?.data?.balance;
        
        let currencydata=response?.data.currency_type;

        setCurrency_type(currencydata)

        let dotval=balmaster.includes('.');
        if(dotval){

          var indexofdot=balmaster.indexOf('.');

          let cutvalue=indexofdot+4

          let finalbalance=balmaster.slice(0,cutvalue)
          finalval=finalbalance;
 

        }else{

          finalval=balmaster;
        }

        
    

        setBalance(finalval);
      }
    } catch (err) {
      console.log('no amount found',err);
    }
  };

  
  const fetchUserlimitdata=async()=>{
    try{

    let response=await apiCall({endpoint:'dashboard/getuserwhtsapplimit',method:'get'})

  if(response && response.data.length>0){

    let whtsapplimit=response?.data?.[0]?.whtsapp_limit ?? '0';

      settotallimit(whtsapplimit)

  }



    }catch(err){
      console.log('Error in fetching UserLimit Data',err)
    }
  }

  const handleLogOut = () => {
    localStorage.removeItem("user-cred");
    resetContext()
    redirectToLogin(true);
    setIsUserDropDown(false);
  };

  if (redirectLogin) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Sidebar />
      <header className="Header">
        <div className="Header_contain">
          <div className="Header_menu">
            <button
              id="Header_menu"
              onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
            >
              <img
                src={DotsIcon}
                alt="menu bar"
                className={`Icon_dots ${!isSidebarCollapsed ? "d-none" : ""}`}
              />
              <img
                className={`Icon_menu ${isSidebarCollapsed ? "d-none" : ""}`}
                src={MenuBar}
                alt="img"
              />
            </button>
          </div>
          {userType === 'admin' || userType === 'super_admin' ? (
  <div className="Header_content justify-content-end">
    <p>{userType === 'admin' ? "Admin" : "Super Admin"}</p>
  </div>
) : userType === 'OEM' ? (
  <div className="Header_content justify-content-end">
    <p>OEM</p>
  </div>
) : (
  <div className="Header_content justify-content-between">
    <div className="WalletLimit">
      <p>Today Limit: <span className="TodayLimit">{totallimit}</span></p>
    </div>
    <p>
    
    {Currency_type === 'Euro' ? (
     <>
     <img src={Eurowallet} alt="wallet icon" className="WalletIcon " /> : 
<span>{balance}</span>
     <img src={euroIcon} alt="euro icon" className="RupeeIcon euro_icon" />

</>
    ) : Currency_type === 'Dollar' ? (
      <>
      <img src={Dollarwallet} alt="wallet icon" className="WalletIcon" />: 
<span>{balance}</span>
      <img src={dollarIcon} alt="euro icon" className="RupeeIcon dollarIcon" />

</>
    
    ) : Currency_type === 'Rupees' ? (
      <>
      <img src={walletIcon} alt="wallet icon" className="WalletIcon" /> : 
<span>{balance}</span>
      <img src={rupeeIcon} alt="euro icon" className="RupeeIcon" />

</>
    ) : (
      <><span>{balance}</span></>
    )}
    </p>
    
  </div>
)}

          <div className="Header_end" ref={dropdownRef}>
            <button
              id="User_close"
              onClick={() => setIsUserDropDown(!isUserDropDown)}
            >
              <PersonIcon />
            </button>

            <div className={`User_drop ${isUserDropDown ? "show" : ""}`}>
              <Link to="/profile" onClick={() => setIsUserDropDown(false)}> <PersonIcon /> Profile</Link>
              <Link to="/changepassword" onClick={() => setIsUserDropDown(false)}><KeyIcon /> Change Password</Link>
              <button onClick={handleLogOut}> <LogoutIcon /> Logout</button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
