import React, { useRef, useState, useEffect } from 'react';
import '../css/wb_customize_campaigns.css'

import Papa from 'papaparse';
import ShowSnackBar from "../components/snackBar";

import { apiCall } from '../services/authServieces';
import { ClipLoader } from 'react-spinners';

import { PhoneInput } from "react-international-phone";




const WbCustomizeCampaigns = () => {
  // let csvdata=[];
  const [loader, setLoader] = useState(false);
  const [snackBar, setSnackBar] = useState({ open: false, severity: true, message: "" });


  const [Campaign, setCampaign] = useState('')
  const fileInputRef = useRef(null);// for opening csv file
  const [csvHeaders, setCsvHeaders] = useState([]); // for csv file headers
  const [fileName, setFileName] = useState('No File Selected'); // for csv filename
  const [message, setMessage] = useState('');// for message
  const [remainingChars, setRemainingChars] = useState(800);//limit for characts
  const [totalContact, settotalContact] = useState('0');
  const [templatetype, settemplatetype] = useState('');
  const [template, settemplate] = useState('');
  const [fileType, setFileType] = useState('');
  const [templatedata, setemplatedata] = useState([]);
  const [templateID, settemplateID] = useState('');
  const [templatename, settemplatename] = useState('');
  const [templatecat, settemplatecat] = useState('');
  const [templatelang, settemplatelang] = useState('');
  const [msgUrl, setmsgUrl] = useState('');
  const [filed, setfiledata] = useState();
  const [showModal, setShowModal] = useState(false);
  const [mobileno, setmobileno] = useState('');
  const [loading, setLoading] = useState(false);
  const [modaltotalContacts, setmodaltotalContacts] = useState('0')
  const [maindata, setmaindata] = useState();
  const [variabledata, setvariabledata] = useState([]);

  const [modalData, setModalData] = useState([]);
  const [variableclick, setvariableclick] = useState('');

  const [pltPrice, setpltPrice] = useState('');
  const [msgPrice, setmsgPrice] = useState('');
  const [userBalance, setuserBalance] = useState('');
  const [finalvalue, setfinalvalue] = useState('');
  const [phoneNumber, setPhoneNumber] = useState("");

  const [contactdata, setcontactdata] = useState([]);
  const [businessNo, setbusinessNo] = useState('');
  const [blockNum, setblockNum] = useState([]);

  const checkfiledata = useRef(null);
  const checkurldata = useRef(null);

  const [dataObject, setDataObject] = useState({});//for globally use data

  const [countrycode, setcountrycode] = useState('')

  const handleImportClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  useEffect(() => {
    // Call the function to fetch contact data
    Handleblocknodata();
    Contactdata();
  }, []);


  const Handleblocknodata = async () => {
    try {
      setLoader(true);
      let response = await apiCall({ endpoint: 'api/get_block_number_data', method: 'get' });

      if (response.success === true) {

        let blocknumdata = response.data.map(item => item.block_number)

        setblockNum(blocknumdata)

      } else {
        setblockNum([])

      }



    } catch (err) {
      console.log('error in fechting blocknodata', err)
    } finally {
      setLoader(false);
    }

  }

  const handleFileChange = (event) => {

    const fileUpload = event.target.files[0];


    setmaindata(fileUpload)

    if (fileUpload && fileUpload.name) {

      setFileName(fileUpload.name)
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvContent = e.target.result;

        Papa.parse(csvContent, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            let parsedData = results.data;

            // Extract headers from CSV
            const headers = results.meta.fields;
            setCsvHeaders(headers);

            // Limit the processing to 50,000 rows
            const MAX_ROWS = 50000;
            if (parsedData.length > MAX_ROWS) {
              parsedData = parsedData.slice(0, MAX_ROWS);
              alert(`Only the first ${MAX_ROWS} rows will be processed.`);
            }
            // Initialize the dataObject with empty arrays for each header
            let newDataObject = {};
            headers.forEach(header => {
              newDataObject[header] = [];
            });

            // Populate the newDataObject with CSV data
            parsedData.forEach(row => {
              headers.forEach(header => {
                if (row[header] !== undefined) {
                  newDataObject[header].push(row[header]);
                }
              });
            });

            // Update state with new dataObject
            setDataObject(newDataObject);


            settotalContact(prev => prev = parsedData.length)
          }
        });
      };
      reader.readAsText(fileUpload);
    }
  };


  // Get Businesss number //
  const Contactdata = async () => {
    try {
      setLoader(true);
      let response = await apiCall({ endpoint: 'api/get-business-no', method: 'get' });

      if (response.data && response.data.length > 0) {
        // Extract business_no from each object in the array
        const businessNumbers = response.data.map(item => item.business_no);

        // Set the extracted business numbers to contactdata
        setcontactdata(businessNumbers);
      } else {
        setcontactdata([]); // Clear the contact data if no data is returned
      }
    } catch (error) {
      console.error('Error fetching contact data:', error);
      setcontactdata([]); // Clear the contact data in case of an error
    } finally {
      setLoader(false);
    }
  };



  const handleHeaderClick = (header) => {

    let msg = message;

    msg = msg.replace('{{' + variableclick + '}}', '{{' + header + '}}')

    setMessage(msg)

    // setMessage((prevMessage) => prevMessage + `{{${header}}}`);  // Append selected header to the message area

  };


  const maxChars = 1024;
  const handleMessageChange = (e) => {
    let inputText = e.target.value;

    // If input exceeds 800 characters, trim it
    if (inputText.length > maxChars) {
      inputText = inputText.substring(0, maxChars);
    }

    // Update the message state and calculate remaining characters
    setMessage(inputText);
    setRemainingChars(maxChars - inputText.length);

  };

  const handleFileTypeChange = async (e) => {

    const selectedFileType = e.target.value;

    settemplatetype(selectedFileType);
    setFileType(selectedFileType);

    try {

      setLoader(true);
      const payload = {
        'templatetype': selectedFileType,
        'requesttype': 'Customize'
      }

      const response = await apiCall({ endpoint: 'api/gettemplate_data', method: 'POST', payload: payload })

      if (response.data && response.data) {

        // Mapping the response to an array of template names or any other format you need
        const templateOptions = response.data.map(item => ({
          name: item.temp_name,
          id: item.temp_id
        }));

        // Setting the template data
        setemplatedata(templateOptions);

      } else {
        setemplatedata([])

      }

    } catch (err) {
      setemplatedata([])
    } finally {
      setLoader(false);
    }



  }


  const handletemplatename = async (e) => {
    setLoader(true);
    try {
      
      let selectedTempName = e.target.value;

      if (selectedTempName == '') {

        setMessage('')

        settemplatename('')
        settemplatecat('')
        settemplatelang('')
        setuserBalance('')

        settemplateID('')

        settemplate('')

        setpltPrice('');
        setmsgPrice('')

      } else {


      let payload = {
        "templatename": selectedTempName
      }
      // let responsemsg=await axios.post('http://localhost:8000/getresponse_msg', { templatename: selectedTempName })


      const responsemsg = await apiCall({ endpoint: 'getresponse_msg', method: 'POST', payload: payload })

      settemplatename(responsemsg?.data?.temp_name)
      settemplatecat(responsemsg?.data?.cat)
      settemplatelang(responsemsg?.data?.lang)
      setuserBalance(responsemsg?.totaldata?.balance)


      if (responsemsg.data.cat?.toUpperCase() === 'UTILITY') {

        setpltPrice(responsemsg?.balancedata?.plt_price);
        setmsgPrice(responsemsg?.balancedata?.utility_price)

      } else {
        setpltPrice(responsemsg?.balancedata?.plt_price);
        setmsgPrice(responsemsg?.balancedata?.marketing_price)

      }

      settemplateID(responsemsg?.data?.temp_id)

      settemplate(selectedTempName)

      setMessage('')


      function extractVariables(msg) {
        let matches = msg.match(/{{(.*?)}}/g);
        return matches ? matches.map(v => v.replace(/[{{}}]/g, '')) : [];
      }

      let msg = responsemsg.data.message;

      let extractvariable = extractVariables(msg)

      setvariabledata(extractvariable);

      setMessage(responsemsg.data.message);


}

    } catch (err) {
      console.log(err)
    } finally {
      setLoader(false);
    }
  }


  // const handleUserFilechange = async (e) => {
  //   try {

  //     let data = e.target.files[0];

  //     setfiledata(data);

  //     if (data) {
  //       checkurldata.current.disabled = true
  //     } 

  //   } catch (err) {

  //     console.log(err)
  //   }

  // }     

  const handleUserFilechange = async (e) => {
    try {
      let data = e.target.files[0];

      if (data) {
        // Check if the file size is less than 3 MB (3 * 1024 * 1024 bytes)
        if (data.size < 2 * 1024 * 1024) {

          setfiledata(data);
          checkurldata.current.disabled = true;
        } else {
          alert("File size should be less than 2 MB.");
          // Optionally, you can clear the file input
          e.target.value = null;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };



  const SendData = async (event) => {
    event.preventDefault();

    try{


  
    if (Campaign.trim() === '') {
      return alert('Please Enter Campaign Title');
    }
    if (mobileno.trim() === '') {
      return alert('Please Select file Mobile No');
    }

    if (fileType !== 'Text' && msgUrl.trim() === '' && !filed) {
      return alert('Please Enter a Media URL or Upload a File');
    }

    if (template.trim() === '') {
      return alert('Please Select Template');
    }

    if (message.trim() === '') {
      return alert('Please Enter a Message');
    }
    if (businessNo.trim() === '') {
      return alert('Please Select Business No');
    }
    if (countrycode.trim() === '') {
      return alert('Please Enter Valid Country Code');
    }
    function extractVariables(msg) {
      let matches = msg.match(/{{(.*?)}}/g);
      return matches ? matches.map(v => v.replace(/[{{}}]/g, '')) : [];
    }


    function filterVariables(variables, data) {
      return variables.filter(v => data.includes(v));
    }


    let finalcolmn = []
    csvHeaders.forEach(data => {
      finalcolmn.push(data)
    })
    let extractedVariables = extractVariables(message);
    let filteredVariables = filterVariables(extractedVariables, finalcolmn);

    //console.log(filteredVariables,'filteredVariables')
    let data = dataObject[mobileno]


    data = data.filter(number => !blockNum.includes(number))

    let sendMsgprice ="";

    let mobiledata = data;
    var contactlength = data.length

    if(countrycode === '+91'){

      sendMsgprice = (Number(pltPrice) + Number(msgPrice)) * data.length;

    }else{

      let payloaddata = {
        "countrycode": countrycode
      }

      let international_pricedata = await apiCall({ endpoint: 'api/getinternational_price', method: 'post', payload: payloaddata })

      if (international_pricedata.success) {

        if (international_pricedata?.data.length) {
          const priceData = international_pricedata.data[0]
          if (templatecat?.toUpperCase() === 'UTILITY') {
            const totalNums = data.length
            const finalPrice = (Number(pltPrice) + parseFloat(priceData.utility_price)) * totalNums
            sendMsgprice = finalPrice;

          } else {
            const totalNums = data.length
            const finalPrice = (Number(pltPrice) + parseFloat(priceData.marketing_price)) * totalNums
            sendMsgprice = finalPrice;
          }
          // console.log(priceData,templatecat)
        }

      } else {

        return alert('You not able to send International Message Please contact Admin')

      }


    }


    // let sendMsgprice = (Number(pltPrice) + Number(msgPrice)) * contactlength;

    let finalsendprice = parseFloat(sendMsgprice);

    let finaluserBal = parseFloat(userBalance);

    if (finalsendprice > finaluserBal) {
      return alert('Your Balance is Low');
    }

    let datafinal = sendMsgprice;

    setfinalvalue(prevval => prevval = datafinal)




    // if (data.length > 5) {
    data = data.slice(0, 5);
    // }

    let finalMessages = []
    for (let i = 0; i < data.length; i++) {
      let mymsg = message;
      for (let j = 0; j < filteredVariables.length; j++) {
        mymsg = mymsg.replace('{{' + filteredVariables[j] + '}}', dataObject[filteredVariables[j]][i]);
      }

      // Push message and mobile number to the finalMessages array
      finalMessages.push({
        mobile: data[i],
        message: mymsg,
        length: mymsg.length
      });
    }

    setModalData(finalMessages);
    setmodaltotalContacts(contactlength);
    setShowModal(true);

  }catch(error){
    if (error?.response) {
     
      setSnackBar({
          open: true,
          severity: false,
          message: error?.response?.data?.error?.sqlMessage || error?.response?.statusText || 'Something went wrong',
      });
  } else {
      // General error handling
      setSnackBar({
          open: true,
          severity: false,
          message: 'An unexpected error occurred. Please try again later.',
      });
  }
      
  }

  }

  const handleSendNow = async () => {
    if (loading) return;
    setLoading(true); // Start loader
    setLoader(true);
    try {

      const formData = new FormData();

      formData.append('template_id', templateID);
      formData.append('template_name', templatename);
      formData.append('template_lang', templatelang);
      formData.append('template_cat', templatecat);
      formData.append('message', message);
      // formData.append('dataObject', JSON.stringify(dataObject));
      formData.append('mobile_colmn', mobileno)
      formData.append('campaigntitle', Campaign)
      formData.append('msgUrl', msgUrl)
      formData.append('msgtype', fileType);

      formData.append('totalmsgprice', finalvalue)
      formData.append('businessNo', businessNo)

      formData.append('countrycode', countrycode);

      // maincsv file data start
      formData.append('mainfiledata', maindata);
      // maincsv file data end


      if (filed) {
        formData.append('file', filed);
      }

      const response = await apiCall({
        endpoint: 'api/save_wb_customize_message',
        method: 'POST',
        payload: formData
      });


      if (response?.success === true) {

        alert("Data Send Successfull")

        window.location.reload();
        // Clear the form or show a success message here if needed
      }



    } catch (error) {

      setSnackBar({
        open: true,
        severity: error?.response?.success,
        message: error?.response?.msg || "An error occurred",
      });

      // if any error in sending data
      setLoading(false);
    } finally {

      setLoading(false); // Reload the page after success or error
      setLoader(false);
    }


  }



  const handleurlchange = async (e) => {

    const urldata = e.target.value;

    try {

      if (urldata.length > 1) {

        setmsgUrl(urldata)
        checkfiledata.current.disabled = true

      } else {

        checkfiledata.current.disabled = false

      }

    }
    catch (err) {

      console.log(err)

    }

  }

  const handleCloseSnackBar = () => {
    setSnackBar((prevState) => ({ ...prevState, open: false }));
  };


  const handlePhoneInputChange = (newCountryCode) => {
    setcountrycode(newCountryCode);
    settemplate('');
    const extractedCountryCode = newCountryCode?.split(" ")[0] || "";
    setPhoneNumber(extractedCountryCode);
  };

  return (
    <>
      <div className="WB_customize_contain">

        <h4 className="Head_title">Customize Campaign</h4>
        <div className="Customize_card">

          <p>To Mobile Number <span className='required_icon'>*</span></p>

          <div className="custm_docs">
            <p>Every column should have column name</p>
            <p>You can Upload data in batches of 50,000 at a time Only .csv file are allowed.</p>
            <h4>File Name : {fileName}</h4>
            <button type="button" onClick={handleImportClick}>Browse Document</button>
          </div>
          <p>Total Contact : <span>{totalContact}</span></p>



          <div className="row gy-3">
            <div className="col-lg-4">
              <div className="form_group11">
                <label for="title">Campaign Title <span className='required_icon'>*</span></label>
                <input type="text" id="title" placeholder="Enter Campaign Title" value={Campaign} onChange={e => setCampaign(e.target.value)} />
              </div>
            </div>
            <div className='country_code_box col-lg-2'>
              <p>Country Code: <span className='required_icon'>*</span></p>
              <div className='country_code_input form_group11'>
                <PhoneInput
                  defaultCountry="in"
                  id="phoneNo"
                  value={countrycode}
                  onChange={handlePhoneInputChange}
                  className="countrycode"
                />
                <input
                  className='countryCodeInput'
                  id="countryCodeInput"
                  type="text"
                  value={phoneNumber}
                  readOnly
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form_group11">
                <label for="number">Mobile Number <span className='required_icon'>*</span></label>
                <select id="number" value={mobileno} onChange={e => setmobileno(e.target.value)}>
                  <option value="">None</option>
                  {csvHeaders.length > 0 ? (
                    csvHeaders.map((header, index) => (
                      <option key={index} value={header}>{header}</option>
                    ))
                  ) : (
                    ''
                  )}
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form_group11">
                <label for="bussinessnumber">Business Number <span className='required_icon'>*</span></label>
                <select id="businessnumber" value={businessNo} onChange={e => setbusinessNo(e.target.value)}>
                  <option value="">None</option>
                  {contactdata.map((row, index) => (
                    <option key={index} value={row}>{row}</option>
                  ))}
                </select>
              </div>
            </div>
            

          </div>
          <div className="row gy-3 mt-1">
            <div className="col-lg-6">
              <div className="form_group11">
                <label for="template type">Template Type <span className='required_icon'>*</span></label>
                <select id="templatetype" value={templatetype} onChange={handleFileTypeChange}>
                  <option >None</option>
                  <option value="Document">Document</option>
                  <option value="Image">Image</option>
                  <option value="Video">Video</option>
                  <option value="Text">Text</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form_group11">
                <label for="template">Template <span className='required_icon'>*</span></label>
                <select id="template" value={template} onChange={handletemplatename}>
                  <option value="">None</option>
                  {templatedata.map((item) => (
                    <option key={item.id} value={item.name}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form_group11">
                <label for="url">Media url <span className='required_icon'>*</span></label>
                <input type="text" id="url" ref={checkurldata} placeholder="Enter Media url"
                  onChange={handleurlchange}
                  disabled={fileType === 'Text'} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form_group11">
                <label for="file">OR</label>
                <input type="file" id="file" ref={checkfiledata} className="p_7"
                  onChange={handleUserFilechange}
                  disabled={fileType === 'Text'}
                  accept={fileType === 'Document' ? 'application/pdf' : fileType === 'Image' ? 'image/*' : fileType === 'Video' ? 'video/*' : fileType === 'Text' ? 'text/plain' : ''}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form_group11">
                <label for="Column">Column <span className='required_icon'>*</span></label>
                <select className="custom_select" id="customSelect" multiple={true} onChange={(e) => handleHeaderClick(e.target.value)}>
                  {csvHeaders.length > 0 ? (
                    csvHeaders.map((header, index) => (
                      <option key={index} value={header}>{header}</option>
                    ))
                  ) : (
                    ''
                  )}
                </select>
              </div>

            </div>
            <div className="col-lg-2">
              <div className="form_group11">
                <label for="Column">Variables <span className='required_icon'>*</span></label>
                <select className="custom_select" id="customSelect" multiple={true} onChange={(e) => setvariableclick(e.target.value)} >
                  {variabledata.length > 0 ? (
                    variabledata.map((header, index) => (
                      <option key={index} value={header}>{header}</option>
                    ))
                  ) : (
                    ''
                  )}

                </select>
              </div>

            </div>
            <div className="col-lg-6">
              <div className="form_group11">
                <label for="Message">Message <span className='required_icon'>*</span></label>
                {/* <textarea rows="3" name="Message" id="Message" placeholder="Type your text message here (up to max 4000 characters) or choose from favorites" value={message}  onChange={(e) => setMessage(e.target.value)}></textarea> */}
                <textarea rows="3" name="Message" id="Message" placeholder="Type your text message here (up to max 1024 characters) or choose from favorites" value={message} onChange={handleMessageChange} disabled></textarea>
              </div>
              <div className="List_card">
                <span><span>{message.length}</span> Character(s) </span>

                <span>Max Characters :   <span>{remainingChars}</span> </span>
              </div>
            </div>
            <div className="col-12">
              <div className="form_group11 mt-0 text-center">
                <button type="button" onClick={SendData}>Send Whtsapp</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`modal fade  ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" aria-labelledby="Sms_privew_modal" aria-hidden={!showModal}>
        <div className="modal-dialog">
          <div className="modal-header">
            <h5 className="modal-title text-center w-100" id="Sms_privew_modal">SMS Preview</h5>
          </div>
          <div className="modal-content Campaigns_Modal_contain">
            <div className="modal-body p-0">
              <table className="Modal_table">
                <thead>
                  <tr>
                    <th>Mobile</th>
                    <th>Message</th>
                    <th>Length</th>
                  </tr>
                </thead>
                <tbody>
                  {modalData.map((data, index) => (
                    <tr key={index}>
                      <td>{data.mobile}</td>
                      <td className="Message_modal"><p>{data.message}</p></td>
                      <td>{data.length}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3" className="text-center">
                      Total Contact: <span>{modaltotalContacts}</span>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div className="form_group1 mt-3 text-center">
                <button type="button" disabled={loading} onClick={handleSendNow} className={loading ? 'd-none Modal_save_btn' : 'Modal_save_btn'}>
                  {loading ? 'Sending...' : 'Send Now'}
                </button>
                <button type="button" className={loading ? '' : 'd-none'} disabled={loading}>
                  {loading ? 'Sending... ' : ''}
                </button>
                <button type="button" className="Modal_cancel_btn ms-2" onClick={() => setShowModal(false)} aria-label="Close">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* template id */}
      <input
        type="hidden"
        value={templateID}
        className='d-none'
      />
      {/* template name */}
      <input
        type="hidden"
        value={templatename}
        className='d-none'
      />

      {/* template category */}
      <input
        type="hidden"
        value={templatecat}
        className='d-none'
      />

      {/* template language  */}

      <input
        type="hidden"
        value={templatelang}
        className='d-none'
      />


      {/* for main file */}

      <input
        type="file"
        ref={fileInputRef}
        className='d-none'
        onChange={handleFileChange}
        accept='.csv'
      />

      <ShowSnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={snackBar.message}
        onClose={handleCloseSnackBar}
      />

      {loader && (
        <div className="spinnerOverlay">
          <ClipLoader loading={loader} size={50} />
        </div>
      )}
    </>
  )
}

export default WbCustomizeCampaigns
