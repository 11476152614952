import React, { useEffect, useState } from 'react';
import '../../css/wb_customize_campaigns.css';
import { PhoneInput } from "react-international-phone";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "@mui/material/Modal";
import { apiCall } from '../../services/authServieces';
import TablePagination from '@mui/material/TablePagination';
import deleteIcon from '../../Assets/images/delete.png';

const AddInternationalPrice = () => {
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [total, setTotal] = useState(0);

    const [phoneNumber, setPhoneNumber] = useState("");
    const [countrycode, setCountryCode] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editData, setEditData] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
    const [rowToDelete, setRowToDelete] = useState(null);  // Track which row to delete

    const [formData, setFormData] = useState({
        id: '',
        countryCode: '',
        countryName: '',
        marketingPrice: '',
        utilityPrice: ''
    });

    useEffect(() => {
        fetchData();
    }, [page, rowsPerPage]);

    const fetchData = async () => {
        try {
            const response = await apiCall({
                endpoint: `admin/get-international-prices?page=${page + 1}&limit=${rowsPerPage}`,
                method: 'GET'
            });

            if (response && Array.isArray(response.data)) {
                setRows(response.data);
                setTotal(response.total || 0);
            } else if (response && typeof response.data === 'object') {
                setRows([response.data]);
                setTotal(1);
            } else {
                setRows([]);
                setTotal(0);
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
            setRows([]);
            setTotal(0);
        }
    };

    const handleEditRow = (rowData) => {
        setEditData(rowData);
        setIsEditMode(true);
        setFormData({
            id: rowData.id || '',
            countryCode: rowData.country_code || '',
            countryName: rowData.country_name || '',
            marketingPrice: rowData.marketing_price || '',
            utilityPrice: rowData.utility_price || ''
        });
        setIsModalOpen(true);
    };

    const handlePhoneInputChange = (newCountryCode) => {
        setCountryCode(newCountryCode);
        const extractedCountryCode = newCountryCode?.split(" ")[0] || "";
        setPhoneNumber(extractedCountryCode);
    };

    const handleOpenModal = () => {
        setIsEditMode(false);
        setFormData({
            id: '',
            countryCode: '',
            countryName: '',
            marketingPrice: '',
            utilityPrice: ''
        });
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSave = async () => {
        if (isEditMode) {
            const updatedData = {
                id: formData.id,
                countryCode: formData.countryCode,
                countryName: formData.countryName,
                marketingPrice: formData.marketingPrice,
                utilityPrice: formData.utilityPrice
            };

            try {
                const response = await apiCall({
                    endpoint: `admin/update-international-prices/${formData.id}`,
                    method: 'PUT',
                    payload: updatedData
                });

                if (response?.message) {
                    alert(response.message);
                } else {
                    alert('Country data updated successfully');
                }

                fetchData();
            } catch (error) {
                if (error?.response?.data?.message) {
                    alert(`Error: ${error.response.data.message}`);
                } else {
                    alert('An error occurred while updating the country data');
                }
                console.error("Error updating country data:", error);
            }
        } else {
            const newRow = { ...formData, countryCode: phoneNumber };

            try {
                const response = await apiCall({
                    endpoint: 'admin/add-international-prices',
                    method: 'POST',
                    payload: newRow
                });

                if (response?.message) {
                    alert(response.message);
                } else {
                    alert('New country added successfully');
                }

                fetchData();
            } catch (error) {
                if (error?.response?.data?.message) {
                    alert(`Error: ${error.response.data.message}`);
                } else {
                    alert('An error occurred while adding the new country');
                }
                console.error("Error adding new country data:", error);
            }
        }

        handleCloseModal();
    };

    const handleDeleteRow = (rowData) => {
        setRowToDelete(rowData);  // Store the row to delete
        setIsConfirmDeleteModalOpen(true);  // Open the confirmation modal
    };

    const handleConfirmDelete = async () => {
        if (rowToDelete) {
        
            let payloaddata={
                id:rowToDelete.id
            }

            try {
                const response = await apiCall({
                    endpoint: `admin/delete-international-price`,
                    method: 'POST',
                    payload:payloaddata

                });

                if (response?.message) {
                    alert(response.message);
                } else {
                    alert('Country data deleted successfully');
                }

                fetchData();
            } catch (error) {
                alert('An error occurred while deleting the country data');
                console.error("Error deleting country data:", error);
            }
        }

        setIsConfirmDeleteModalOpen(false);  // Close confirmation modal after action
    };

    const handleCancelDelete = () => {
        setIsConfirmDeleteModalOpen(false);  // Close the confirmation modal without action
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <div className="WB_customize_contain">
                <h4 className="Head_title">
                    International Prices
                    <button
                        type="button"
                        className="add_btn6 add_country_btn"
                        onClick={handleOpenModal}
                    >
                        <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZBAMAAAA2x5hQAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABVQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAEgEApAAAAAd0Uk5TACJwoP+/70bJztwAAAA/SURBVHicY2RABowEeYwK95F4zA57kXisDrupymM0UWBg/MDPwHDnPJDH5AR1yL99GHK0sB3Tf6h+xxdKMAAAXRgYGiPFdGAAAAAASUVORK5CYII="
                            alt="img"
                        />
                        Add Country
                    </button>
                </h4>

                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table" className="Table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Country Code</TableCell>
                                    <TableCell>Country Name</TableCell>
                                    <TableCell>Marketing Price</TableCell>
                                    <TableCell>Utility Price</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.length > 0 ? (
                                    rows.map((row, index) => (
                                        <TableRow key={index} hover role="checkbox">
                                            <TableCell>{row.country_code}</TableCell>
                                            <TableCell>{row.country_name}</TableCell>
                                            <TableCell>{row.marketing_price}</TableCell>
                                            <TableCell>{row.utility_price}</TableCell>
                                            <TableCell>
                                                <EditIcon
                                                    className="icon-spacing"
                                                    onClick={() => handleEditRow(row)}
                                                />
                                                <button
                                                    onClick={() => handleDeleteRow(row)}
                                                    className="ChatBotDeleteBtn"
                                                >
                                                    <img src={deleteIcon} alt="Delete" />
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={5} align="center">
                                            No data available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>

                <Modal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="add-country-modal"
                    aria-describedby="add-country-form"
                >
                    <div className="modal_container">
                        <h2 className="modal_header_new">{isEditMode ? 'Edit Country' : 'Add Country'}</h2>
                        <div className="Customize_card">
                            <div className="row gy-3">
                                <div className="country_code_box col-lg-6">
                                    <p>Country Code: <span className="required_icon">*</span></p>
                                    <div className="country_code_input form_group11">
                                        <PhoneInput
                                            defaultCountry="in"
                                            id="phoneNo"
                                            value={isEditMode ? formData.countryCode : phoneNumber}
                                            onChange={handlePhoneInputChange}
                                            disabled={isEditMode}
                                            className="countrycode"
                                        />
                                         <input
                                            className="countryCodeInput"
                                            id="countryCodeInput"
                                            type="text"
                                            value={isEditMode ? formData.countryCode : phoneNumber}

                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form_group11">
                                        <label htmlFor="title">Country Name <span className="required_icon">*</span></label>
                                        <input
                                            type="text"
                                            placeholder="Enter Country Name"
                                            value={formData.countryName}
                                            onChange={(e) => setFormData({ ...formData, countryName: e.target.value })}
                                            disabled={isEditMode}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form_group11">
                                        <label htmlFor="marketingPrice">Marketing Price <span className="required_icon">*</span></label>
                                        <input
                                        className='input_h_s'
                                            type="number"
                                            id="marketingPrice"
                                            placeholder="Enter Marketing Price"
                                            value={formData.marketingPrice}
                                            onChange={(e) => setFormData({ ...formData, marketingPrice: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form_group11">
                                        <label htmlFor="UtilityPrice">Utility Price <span className="required_icon">*</span></label>
                                        <input
                                        className='input_h_s'
                                            type="number"
                                            id="UtilityPrice"
                                            placeholder="Enter Utility Price"
                                            value={formData.utilityPrice}
                                            onChange={(e) => setFormData({ ...formData, utilityPrice: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btn_m_save">
                            <button type="button" className="btn btn-primary add_country" onClick={handleSave}>
                                {isEditMode ? 'Save Changes' : 'Add Country'}
                            </button>
                            <button type="button" className="btn btn-danger" onClick={handleCloseModal}>
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>

                {/* Confirmation Modal */}
                <Modal
                    open={isConfirmDeleteModalOpen}
                    onClose={handleCancelDelete}
                    aria-labelledby="confirm-delete-modal"
                    aria-describedby="confirm-delete"
                >
                    <div className="modal_container">
                        <h2 className="modal_header_new">Confirm Deletion</h2>
                        <p>Are you sure you want to delete this country data?</p>
                        <div className="btn_m_save">
                            <button type="button" className="btn btn-danger" onClick={handleConfirmDelete}>
                                Yes, Delete
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={handleCancelDelete}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default AddInternationalPrice;
