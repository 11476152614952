const Countries = [
    {
        "code": "GS",
        "label": "South Georgia",
        "phone": "+500"
    },
    {
        "code": "CH",
        "label": "Switzerland",
        "phone": "+41"
    },
    {
        "code": "SL",
        "label": "Sierra Leone",
        "phone": "+232"
    },
    {
        "code": "HU",
        "label": "Hungary",
        "phone": "+36"
    },
    {
        "code": "TW",
        "label": "Taiwan",
        "phone": "+886"
    },
    {
        "code": "WF",
        "label": "Wallis and Futuna",
        "phone": "+681"
    },
    {
        "code": "BB",
        "label": "Barbados",
        "phone": "+1246"
    },
    {
        "code": "PN",
        "label": "Pitcairn Islands",
        "phone": "+64"
    },
    {
        "code": "CI",
        "label": "Ivory Coast",
        "phone": "+225"
    },
    {
        "code": "TN",
        "label": "Tunisia",
        "phone": "+216"
    },
    {
        "code": "IT",
        "label": "Italy",
        "phone": "+39"
    },
    {
        "code": "BJ",
        "label": "Benin",
        "phone": "+229"
    },
    {
        "code": "ID",
        "label": "Indonesia",
        "phone": "+62"
    },
    {
        "code": "CV",
        "label": "Cape Verde",
        "phone": "+238"
    },
    {
        "code": "KN",
        "label": "Saint Kitts and Nevis",
        "phone": "+1869"
    },
    {
        "code": "LA",
        "label": "Laos",
        "phone": "+856"
    },
    {
        "code": "BQ",
        "label": "Caribbean Netherlands",
        "phone": "+599"
    },
    {
        "code": "UG",
        "label": "Uganda",
        "phone": "+256"
    },
    {
        "code": "AD",
        "label": "Andorra",
        "phone": "+376"
    },
    {
        "code": "BI",
        "label": "Burundi",
        "phone": "+257"
    },
    {
        "code": "ZA",
        "label": "South Africa",
        "phone": "+27"
    },
    {
        "code": "FR",
        "label": "France",
        "phone": "+33"
    },
    {
        "code": "LY",
        "label": "Libya",
        "phone": "+218"
    },
    {
        "code": "MX",
        "label": "Mexico",
        "phone": "+52"
    },
    {
        "code": "GA",
        "label": "Gabon",
        "phone": "+241"
    },
    {
        "code": "MP",
        "label": "Northern Mariana Islands",
        "phone": "+1670"
    },
    {
        "code": "MK",
        "label": "North Macedonia",
        "phone": "+389"
    },
    {
        "code": "CN",
        "label": "China",
        "phone": "+86"
    },
    {
        "code": "YE",
        "label": "Yemen",
        "phone": "+967"
    },
    {
        "code": "BL",
        "label": "Saint Barthélemy",
        "phone": "+590"
    },
    {
        "code": "GG",
        "label": "Guernsey",
        "phone": "+44"
    },
    {
        "code": "SB",
        "label": "Solomon Islands",
        "phone": "+677"
    },
    {
        "code": "SJ",
        "label": "Svalbard and Jan Mayen",
        "phone": "+4779"
    },
    {
        "code": "FO",
        "label": "Faroe Islands",
        "phone": "+298"
    },
    {
        "code": "UZ",
        "label": "Uzbekistan",
        "phone": "+998"
    },
    {
        "code": "EG",
        "label": "Egypt",
        "phone": "+20"
    },
    {
        "code": "SN",
        "label": "Senegal",
        "phone": "+221"
    },
    {
        "code": "LK",
        "label": "Sri Lanka",
        "phone": "+94"
    },
    {
        "code": "PS",
        "label": "Palestine",
        "phone": "+970"
    },
    {
        "code": "BD",
        "label": "Bangladesh",
        "phone": "+880"
    },
    {
        "code": "PE",
        "label": "Peru",
        "phone": "+51"
    },
    {
        "code": "SG",
        "label": "Singapore",
        "phone": "+65"
    },
    {
        "code": "TR",
        "label": "Turkey",
        "phone": "+90"
    },
    {
        "code": "AF",
        "label": "Afghanistan",
        "phone": "+93"
    },
    {
        "code": "AW",
        "label": "Aruba",
        "phone": "+297"
    },
    {
        "code": "CK",
        "label": "Cook Islands",
        "phone": "+682"
    },
    {
        "code": "GB",
        "label": "United Kingdom",
        "phone": "+44"
    },
    {
        "code": "ZM",
        "label": "Zambia",
        "phone": "+260"
    },
    {
        "code": "FI",
        "label": "Finland",
        "phone": "+358"
    },
    {
        "code": "NE",
        "label": "Niger",
        "phone": "+227"
    },
    {
        "code": "CX",
        "label": "Christmas Island",
        "phone": "+61"
    },
    {
        "code": "TK",
        "label": "Tokelau",
        "phone": "+690"
    },
    {
        "code": "GW",
        "label": "Guinea-Bissau",
        "phone": "+245"
    },
    {
        "code": "AZ",
        "label": "Azerbaijan",
        "phone": "+994"
    },
    {
        "code": "RE",
        "label": "Réunion",
        "phone": "+262"
    },
    {
        "code": "DJ",
        "label": "Djibouti",
        "phone": "+253"
    },
    {
        "code": "KP",
        "label": "North Korea",
        "phone": "+850"
    },
    {
        "code": "MU",
        "label": "Mauritius",
        "phone": "+230"
    },
    {
        "code": "MS",
        "label": "Montserrat",
        "phone": "+1664"
    },
    {
        "code": "VI",
        "label": "United States Virgin Islands",
        "phone": "+1340"
    },
    {
        "code": "CO",
        "label": "Colombia",
        "phone": "+57"
    },
    {
        "code": "GR",
        "label": "Greece",
        "phone": "+30"
    },
    {
        "code": "HR",
        "label": "Croatia",
        "phone": "+385"
    },
    {
        "code": "MA",
        "label": "Morocco",
        "phone": "+212"
    },
    {
        "code": "DZ",
        "label": "Algeria",
        "phone": "+213"
    },
    {
        "code": "AQ",
        "label": "Antarctica"
    },
    {
        "code": "NL",
        "label": "Netherlands",
        "phone": "+31"
    },
    {
        "code": "SD",
        "label": "Sudan",
        "phone": "+249"
    },
    {
        "code": "FJ",
        "label": "Fiji",
        "phone": "+679"
    },
    {
        "code": "LI",
        "label": "Liechtenstein",
        "phone": "+423"
    },
    {
        "code": "NP",
        "label": "Nepal",
        "phone": "+977"
    },
    {
        "code": "PR",
        "label": "Puerto Rico",
        "phone": "+1787"
    },
    {
        "code": "GE",
        "label": "Georgia",
        "phone": "+995"
    },
    {
        "code": "PK",
        "label": "Pakistan",
        "phone": "+92"
    },
    {
        "code": "MC",
        "label": "Monaco",
        "phone": "+377"
    },
    {
        "code": "BW",
        "label": "Botswana",
        "phone": "+267"
    },
    {
        "code": "LB",
        "label": "Lebanon",
        "phone": "+961"
    },
    {
        "code": "PG",
        "label": "Papua New Guinea",
        "phone": "+675"
    },
    {
        "code": "YT",
        "label": "Mayotte",
        "phone": "+262"
    },
    {
        "code": "DO",
        "label": "Dominican Republic",
        "phone": "+1809"
    },
    {
        "code": "NF",
        "label": "Norfolk Island",
        "phone": "+672"
    },
    {
        "code": "BV",
        "label": "Bouvet Island",
        "phone": "+47"
    },
    {
        "code": "QA",
        "label": "Qatar",
        "phone": "+974"
    },
    {
        "code": "MG",
        "label": "Madagascar",
        "phone": "+261"
    },
    {
        "code": "IN",
        "label": "India",
        "phone": "+91"
    },
    {
        "code": "SY",
        "label": "Syria",
        "phone": "+963"
    },
    {
        "code": "ME",
        "label": "Montenegro",
        "phone": "+382"
    },
    {
        "code": "SZ",
        "label": "Eswatini",
        "phone": "+268"
    },
    {
        "code": "PY",
        "label": "Paraguay",
        "phone": "+595"
    },
    {
        "code": "SV",
        "label": "El Salvador",
        "phone": "+503"
    },
    {
        "code": "UA",
        "label": "Ukraine",
        "phone": "+380"
    },
    {
        "code": "IM",
        "label": "Isle of Man",
        "phone": "+44"
    },
    {
        "code": "NA",
        "label": "Namibia",
        "phone": "+264"
    },
    {
        "code": "AE",
        "label": "United Arab Emirates",
        "phone": "+971"
    },
    {
        "code": "BG",
        "label": "Bulgaria",
        "phone": "+359"
    },
    {
        "code": "GL",
        "label": "Greenland",
        "phone": "+299"
    },
    {
        "code": "DE",
        "label": "Germany",
        "phone": "+49"
    },
    {
        "code": "KH",
        "label": "Cambodia",
        "phone": "+855"
    },
    {
        "code": "IQ",
        "label": "Iraq",
        "phone": "+964"
    },
    {
        "code": "TF",
        "label": "French Southern and Antarctic Lands",
        "phone": "+262"
    },
    {
        "code": "SE",
        "label": "Sweden",
        "phone": "+46"
    },
    {
        "code": "CU",
        "label": "Cuba",
        "phone": "+53"
    },
    {
        "code": "KG",
        "label": "Kyrgyzstan",
        "phone": "+996"
    },
    {
        "code": "RU",
        "label": "Russia",
        "phone": "+73"
    },
    {
        "code": "MY",
        "label": "Malaysia",
        "phone": "+60"
    },
    {
        "code": "ST",
        "label": "São Tomé and Príncipe",
        "phone": "+239"
    },
    {
        "code": "CY",
        "label": "Cyprus",
        "phone": "+357"
    },
    {
        "code": "CA",
        "label": "Canada",
        "phone": "+1"
    },
    {
        "code": "MW",
        "label": "Malawi",
        "phone": "+265"
    },
    {
        "code": "SA",
        "label": "Saudi Arabia",
        "phone": "+966"
    },
    {
        "code": "BA",
        "label": "Bosnia and Herzegovina",
        "phone": "+387"
    },
    {
        "code": "ET",
        "label": "Ethiopia",
        "phone": "+251"
    },
    {
        "code": "ES",
        "label": "Spain",
        "phone": "+34"
    },
    {
        "code": "SI",
        "label": "Slovenia",
        "phone": "+386"
    },
    {
        "code": "OM",
        "label": "Oman",
        "phone": "+968"
    },
    {
        "code": "PM",
        "label": "Saint Pierre and Miquelon",
        "phone": "+508"
    },
    {
        "code": "MO",
        "label": "Macau",
        "phone": "+853"
    },
    {
        "code": "SM",
        "label": "San Marino",
        "phone": "+378"
    },
    {
        "code": "LS",
        "label": "Lesotho",
        "phone": "+266"
    },
    {
        "code": "MH",
        "label": "Marshall Islands",
        "phone": "+692"
    },
    {
        "code": "SX",
        "label": "Sint Maarten",
        "phone": "+1721"
    },
    {
        "code": "IS",
        "label": "Iceland",
        "phone": "+354"
    },
    {
        "code": "LU",
        "label": "Luxembourg",
        "phone": "+352"
    },
    {
        "code": "AR",
        "label": "Argentina",
        "phone": "+54"
    },
    {
        "code": "TC",
        "label": "Turks and Caicos Islands",
        "phone": "+1649"
    },
    {
        "code": "NR",
        "label": "Nauru",
        "phone": "+674"
    },
    {
        "code": "CC",
        "label": "Cocos (Keeling) Islands",
        "phone": "+61"
    },
    {
        "code": "EH",
        "label": "Western Sahara",
        "phone": "+2125288"
    },
    {
        "code": "DM",
        "label": "Dominica",
        "phone": "+1767"
    },
    {
        "code": "CR",
        "label": "Costa Rica",
        "phone": "+506"
    },
    {
        "code": "AU",
        "label": "Australia",
        "phone": "+61"
    },
    {
        "code": "TH",
        "label": "Thailand",
        "phone": "+66"
    },
    {
        "code": "HT",
        "label": "Haiti",
        "phone": "+509"
    },
    {
        "code": "TV",
        "label": "Tuvalu",
        "phone": "+688"
    },
    {
        "code": "HN",
        "label": "Honduras",
        "phone": "+504"
    },
    {
        "code": "GQ",
        "label": "Equatorial Guinea",
        "phone": "+240"
    },
    {
        "code": "LC",
        "label": "Saint Lucia",
        "phone": "+1758"
    },
    {
        "code": "PF",
        "label": "French Polynesia",
        "phone": "+689"
    },
    {
        "code": "BY",
        "label": "Belarus",
        "phone": "+375"
    },
    {
        "code": "LV",
        "label": "Latvia",
        "phone": "+371"
    },
    {
        "code": "PW",
        "label": "Palau",
        "phone": "+680"
    },
    {
        "code": "GP",
        "label": "Guadeloupe",
        "phone": "+590"
    },
    {
        "code": "PH",
        "label": "Philippines",
        "phone": "+63"
    },
    {
        "code": "GI",
        "label": "Gibraltar",
        "phone": "+350"
    },
    {
        "code": "DK",
        "label": "Denmark",
        "phone": "+45"
    },
    {
        "code": "CM",
        "label": "Cameroon",
        "phone": "+237"
    },
    {
        "code": "GN",
        "label": "Guinea",
        "phone": "+224"
    },
    {
        "code": "BH",
        "label": "Bahrain",
        "phone": "+973"
    },
    {
        "code": "SR",
        "label": "Suriname",
        "phone": "+597"
    },
    {
        "code": "CD",
        "label": "DR Congo",
        "phone": "+243"
    },
    {
        "code": "SO",
        "label": "Somalia",
        "phone": "+252"
    },
    {
        "code": "CZ",
        "label": "Czechia",
        "phone": "+420"
    },
    {
        "code": "NC",
        "label": "New Caledonia",
        "phone": "+687"
    },
    {
        "code": "VU",
        "label": "Vanuatu",
        "phone": "+678"
    },
    {
        "code": "SH",
        "label": "Saint Helena, Ascension and Tristan da Cunha",
        "phone": "+290"
    },
    {
        "code": "TG",
        "label": "Togo",
        "phone": "+228"
    },
    {
        "code": "VG",
        "label": "British Virgin Islands",
        "phone": "+1284"
    },
    {
        "code": "KE",
        "label": "Kenya",
        "phone": "+254"
    },
    {
        "code": "NU",
        "label": "Niue",
        "phone": "+683"
    },
    {
        "code": "HM",
        "label": "Heard Island and McDonald Islands"
    },
    {
        "code": "RW",
        "label": "Rwanda",
        "phone": "+250"
    },
    {
        "code": "EE",
        "label": "Estonia",
        "phone": "+372"
    },
    {
        "code": "RO",
        "label": "Romania",
        "phone": "+40"
    },
    {
        "code": "TT",
        "label": "Trinidad and Tobago",
        "phone": "+1868"
    },
    {
        "code": "GY",
        "label": "Guyana",
        "phone": "+592"
    },
    {
        "code": "TL",
        "label": "Timor-Leste",
        "phone": "+670"
    },
    {
        "code": "VN",
        "label": "Vietnam",
        "phone": "+84"
    },
    {
        "code": "UY",
        "label": "Uruguay",
        "phone": "+598"
    },
    {
        "code": "VA",
        "label": "Vatican City",
        "phone": "+3906698"
    },
    {
        "code": "HK",
        "label": "Hong Kong",
        "phone": "+852"
    },
    {
        "code": "AT",
        "label": "Austria",
        "phone": "+43"
    },
    {
        "code": "AG",
        "label": "Antigua and Barbuda",
        "phone": "+1268"
    },
    {
        "code": "TM",
        "label": "Turkmenistan",
        "phone": "+993"
    },
    {
        "code": "MZ",
        "label": "Mozambique",
        "phone": "+258"
    },
    {
        "code": "PA",
        "label": "Panama",
        "phone": "+507"
    },
    {
        "code": "FM",
        "label": "Micronesia",
        "phone": "+691"
    },
    {
        "code": "IE",
        "label": "Ireland",
        "phone": "+353"
    },
    {
        "code": "CW",
        "label": "Curaçao",
        "phone": "+599"
    },
    {
        "code": "GF",
        "label": "French Guiana",
        "phone": "+594"
    },
    {
        "code": "NO",
        "label": "Norway",
        "phone": "+47"
    },
    {
        "code": "AX",
        "label": "Åland Islands",
        "phone": "+35818"
    },
    {
        "code": "CF",
        "label": "Central African Republic",
        "phone": "+236"
    },
    {
        "code": "BF",
        "label": "Burkina Faso",
        "phone": "+226"
    },
    {
        "code": "ER",
        "label": "Eritrea",
        "phone": "+291"
    },
    {
        "code": "TZ",
        "label": "Tanzania",
        "phone": "+255"
    },
    {
        "code": "KR",
        "label": "South Korea",
        "phone": "+82"
    },
    {
        "code": "JO",
        "label": "Jordan",
        "phone": "+962"
    },
    {
        "code": "MR",
        "label": "Mauritania",
        "phone": "+222"
    },
    {
        "code": "LT",
        "label": "Lithuania",
        "phone": "+370"
    },
    {
        "code": "UM",
        "label": "United States Minor Outlying Islands",
        "phone": "+268"
    },
    {
        "code": "SK",
        "label": "Slovakia",
        "phone": "+421"
    },
    {
        "code": "AO",
        "label": "Angola",
        "phone": "+244"
    },
    {
        "code": "KZ",
        "label": "Kazakhstan",
        "phone": "+76"
    },
    {
        "code": "MD",
        "label": "Moldova",
        "phone": "+373"
    },
    {
        "code": "ML",
        "label": "Mali",
        "phone": "+223"
    },
    {
        "code": "FK",
        "label": "Falkland Islands",
        "phone": "+500"
    },
    {
        "code": "AM",
        "label": "Armenia",
        "phone": "+374"
    },
    {
        "code": "WS",
        "label": "Samoa",
        "phone": "+685"
    },
    {
        "code": "JE",
        "label": "Jersey",
        "phone": "+44"
    },
    {
        "code": "JP",
        "label": "Japan",
        "phone": "+81"
    },
    {
        "code": "BO",
        "label": "Bolivia",
        "phone": "+591"
    },
    {
        "code": "CL",
        "label": "Chile",
        "phone": "+56"
    },
    {
        "code": "US",
        "label": "United States",
        "phone": "+1201"
    },
    {
        "code": "VC",
        "label": "Saint Vincent and the Grenadines",
        "phone": "+1784"
    },
    {
        "code": "BM",
        "label": "Bermuda",
        "phone": "+1441"
    },
    {
        "code": "SC",
        "label": "Seychelles",
        "phone": "+248"
    },
    {
        "code": "IO",
        "label": "British Indian Ocean Territory",
        "phone": "+246"
    },
    {
        "code": "GT",
        "label": "Guatemala",
        "phone": "+502"
    },
    {
        "code": "EC",
        "label": "Ecuador",
        "phone": "+593"
    },
    {
        "code": "MQ",
        "label": "Martinique",
        "phone": "+596"
    },
    {
        "code": "TJ",
        "label": "Tajikistan",
        "phone": "+992"
    },
    {
        "code": "MT",
        "label": "Malta",
        "phone": "+356"
    },
    {
        "code": "GM",
        "label": "Gambia",
        "phone": "+220"
    },
    {
        "code": "NG",
        "label": "Nigeria",
        "phone": "+234"
    },
    {
        "code": "BS",
        "label": "Bahamas",
        "phone": "+1242"
    },
    {
        "code": "XK",
        "label": "Kosovo",
        "phone": "+383"
    },
    {
        "code": "KW",
        "label": "Kuwait",
        "phone": "+965"
    },
    {
        "code": "MV",
        "label": "Maldives",
        "phone": "+960"
    },
    {
        "code": "SS",
        "label": "South Sudan",
        "phone": "+211"
    },
    {
        "code": "IR",
        "label": "Iran",
        "phone": "+98"
    },
    {
        "code": "AL",
        "label": "Albania",
        "phone": "+355"
    },
    {
        "code": "BR",
        "label": "Brazil",
        "phone": "+55"
    },
    {
        "code": "RS",
        "label": "Serbia",
        "phone": "+381"
    },
    {
        "code": "BZ",
        "label": "Belize",
        "phone": "+501"
    },
    {
        "code": "MM",
        "label": "Myanmar",
        "phone": "+95"
    },
    {
        "code": "BT",
        "label": "Bhutan",
        "phone": "+975"
    },
    {
        "code": "VE",
        "label": "Venezuela",
        "phone": "+58"
    },
    {
        "code": "LR",
        "label": "Liberia",
        "phone": "+231"
    },
    {
        "code": "JM",
        "label": "Jamaica",
        "phone": "+1876"
    },
    {
        "code": "PL",
        "label": "Poland",
        "phone": "+48"
    },
    {
        "code": "KY",
        "label": "Cayman Islands",
        "phone": "+1345"
    },
    {
        "code": "BN",
        "label": "Brunei",
        "phone": "+673"
    },
    {
        "code": "KM",
        "label": "Comoros",
        "phone": "+269"
    },
    {
        "code": "GU",
        "label": "Guam",
        "phone": "+1671"
    },
    {
        "code": "TO",
        "label": "Tonga",
        "phone": "+676"
    },
    {
        "code": "KI",
        "label": "Kiribati",
        "phone": "+686"
    },
    {
        "code": "GH",
        "label": "Ghana",
        "phone": "+233"
    },
    {
        "code": "TD",
        "label": "Chad",
        "phone": "+235"
    },
    {
        "code": "ZW",
        "label": "Zimbabwe",
        "phone": "+263"
    },
    {
        "code": "MF",
        "label": "Saint Martin",
        "phone": "+590"
    },
    {
        "code": "MN",
        "label": "Mongolia",
        "phone": "+976"
    },
    {
        "code": "PT",
        "label": "Portugal",
        "phone": "+351"
    },
    {
        "code": "AS",
        "label": "American Samoa",
        "phone": "+1684"
    },
    {
        "code": "CG",
        "label": "Republic of the Congo",
        "phone": "+242"
    },
    {
        "code": "BE",
        "label": "Belgium",
        "phone": "+32"
    },
    {
        "code": "IL",
        "label": "Israel",
        "phone": "+972"
    },
    {
        "code": "NZ",
        "label": "New Zealand",
        "phone": "+64"
    },
    {
        "code": "NI",
        "label": "Nicaragua",
        "phone": "+505"
    },
    {
        "code": "AI",
        "label": "Anguilla",
        "phone": "+1264"
    }
]

module.exports = Countries