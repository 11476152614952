import React, { useEffect, useState } from "react";
import "../css/wb_template.css";
import { Link } from "react-router-dom";
import Eye from "../Assets/images/eye.png";
import Delete from "../Assets/images/delete.png";
import Plus from "../Assets/images/plus.png";
import { apiCall } from '../services/authServieces';
import ShowSnackBar from "../components/snackBar";
import AlertDialog from '../components/deleteComponent';
import { deleteTemplate } from '../services/templateService';
import PdfIcon from '../Assets/images/pdf.png';
import TablePagination from '@mui/material/TablePagination';
import { ClipLoader } from "react-spinners";

const WbTemplate = () => {
  const [loading, setLoading] = useState(false);
  const [isTemplate, setIsTemplate] = useState([]);
  const [snackBar, setSnackBar] = useState({ open: false, severity: '', message: '' });
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTemplateName, setSelectedTemplateName] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [page, setPage] = useState(0); // Pagination: current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Pagination: rows per page
  const [totalTemplates, setTotalTemplates] = useState(0); // Total number of templates

  const handleClickOpen = (templateName, templateId) => {
    setSelectedTemplateName(templateName);
    setSelectedTemplateId(templateId);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = async (templateId) => {
    setLoading(true);
    try {
      const res = await deleteTemplate({ payload: templateId });
  
      if (res.success) {
        setSnackBar({
          open: true,
          severity: "success",
          message: res.message,
        });
  
        // Filter out the deleted template without reloading
        const updatedTemplates = isTemplate.filter(template => template.temp_id !== templateId);
        setIsTemplate(updatedTemplates);
  
        // Optionally, refetch templates to ensure pagination is accurate
        if (updatedTemplates.length === 0 && page > 0) {
          setPage(page - 1); // Move to the previous page if the current one is empty
        } else {
          // Re-fetch data to ensure updated state for pagination
          fetchTemplates(); 
        }
      }
    } catch (err) {
      setSnackBar({
        open: true,
        severity: "error",
        message: err?.response?.data?.message || "An error occurred",
      });
    } finally{
      setLoading(false);
    }
    setOpenDialog(false);
  };
  

  useEffect(() => {
    fetchTemplates();
  }, [page, rowsPerPage]); // Re-fetch data on page or rowsPerPage change
  
  const fetchTemplates = async () => {
    setLoading(true);
    try {
      const res = await apiCall({
        endpoint: `templates/get-templates-pagination?page=${page + 1}&limit=${rowsPerPage}`,
        method: 'GET',
      });
  
      if (res?.success) {
        const formattedData = res.data.map(item => {
          const date = new Date(item.date_time);
          const formattedDate = date.toLocaleString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          });
  
          return {
            ...item,
            date_time: formattedDate,
          };
        });
  
        setIsTemplate(formattedData || []);
        setTotalTemplates(res.totalCount);
      }
    } catch (error) {
      setSnackBar({
        open: true,
        severity: 'error',
        message: error?.response?.data?.message || "An error occurred",
      });
    } finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    const getTemplates = async () => {
      setLoading(true);
      try {
        const res = await apiCall({
          endpoint: `templates/get-templates-pagination?page=${page + 1}&limit=${rowsPerPage}`,
          method: 'GET',
        });

        if (res?.success) {
          const formattedData = res.data.map(item => {
            const date = new Date(item.date_time);
            const formattedDate = date.toLocaleString('en-GB', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            });

            return {
              ...item,
              date_time: formattedDate,
            };
          });

          setIsTemplate(formattedData || []);
          setTotalTemplates(res.totalCount); // Update total templates
        }
      } catch (error) {
        setSnackBar({
          open: true,
          severity: 'error',
          message: error?.response?.data?.message || "An error occurred",
        });
      } finally{
        setLoading(false);
      }
    };

    getTemplates();
  }, [page, rowsPerPage]); // Re-fetch data on page or rowsPerPage change

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
  };

  const handleCloseSnackBar = () => {
    setSnackBar((prevState) => ({ ...prevState, open: false }));
  };

  const handlePreview = (template) => {
    setSelectedTemplate(template); // Set the selected template for the modal
  };

  return (
    <>
      <div className="Template_id_contian1">
        <h4 className="Head_titleTemplate">
          Manage Template
          <Link className="add_btn1 text-nowrap WMadd_btntemp1" to="/wbaddtemplate">
            <img src={Plus} alt="img" /> Add Template
          </Link>
        </h4>
        <div className="Template_id_Card1">
          <div className="table_contain" id="tableContain">
            <table className="Table" id="Table">
              <thead>
                <tr>
                  <th>S_No.</th>
                  <th>Template ID</th>
                  <th>Template Name</th>
                  <th>Template Type</th>
                  <th>Preview</th>
                  <th>Category</th>
                  <th>Language</th>
                  <th>Date/Time</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {isTemplate.length > 0 ? (
                  isTemplate.map((template, index) => (
                    <tr key={"Template"+ index}>
                      <td>{index + 1}</td>
                      <td>{template.temp_id}</td>
                      <td>{template.temp_name}</td>
                      <td>{template.t_type}</td>
                      <td>
                        <button
                          className="download_icon"
                          onClick={() => handlePreview(template)} // Pass template data to modal
                          data-bs-toggle="modal"
                          data-bs-target="#Preview_modal"
                        >
                          <img src={Eye} alt="preview" />
                        </button>
                      </td>
                      <td>{template.cat}</td>
                      <td>{template.lang}</td>
                      <td>{template.date}, {template.time}</td>
                      <td>
                      {['ACCEPTED', 'REJECTED', 'PENDING','APPROVED'].includes(template.status) && (
                        <span className={template.status.toLowerCase()}>
                          {template.status}
                        </span>
                      )}
                      </td>
                      <td>
                        <button className="del_btn1" onClick={() => handleClickOpen(template.temp_name, template.temp_id)}>
                          <img src={Delete} alt="delete" />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">No Templates Found</td>
                  </tr>
                )}
              </tbody>
            </table>
            {/* Delete confirmation dialog */}
            <AlertDialog
              open={openDialog}
              onClose={handleClose}
              templateName={selectedTemplateName}
              handleDelete={() => handleDelete(selectedTemplateId)}
            />            
          </div>

          {/* Table Pagination */}
          <TablePagination
            component="div"
            count={totalTemplates}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]} // Define rows per page options
          />
        </div>
      </div>

      {/* Modal for Template Preview */}
      <div className="modal fade" id="Preview_modal" tabIndex="-1" aria-labelledby="Preview_modal_Label" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content p-0">
            <div className="modal-header">
              <h5 className="modal-title" id="Preview_modal_Label">
                Template Preview
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body ">
              {selectedTemplate ? (
                <div className="Modal_Table_contain">
                  <div className="templateContainer">
                    <div
                      style={{
                        backgroundColor: '#ffffff',
                        borderRadius: '10px',
                        width: '100%',
                      }}
                    >
                      {selectedTemplate.t_type === 'Media' && selectedTemplate.media_type === 'Document' && (
                        <div
                          className="templateHeader d-flex justify-content-center align-items-center"
                          style={{
                            backgroundImage: 'url(../Assets/images/whatsappBG.jpg)',
                            maxheight: '300px',
                            flexDirection: 'column',
                            margin: 'auto',
                            borderRadius: '5px',
                            backgroundColor: '#f5f5f5',
                          }}
                        >
                          <img src={PdfIcon} alt="pdf img" height={50} width={100} />
                          <a href={`${selectedTemplate.file_doc}`} target="_blank">
                            View Document
                          </a>
                        </div>
                      )}

                      {selectedTemplate.t_type === 'Media' && selectedTemplate.media_type === 'Image' && (
                        <div
                          className="templateHeader d-flex justify-content-center align-items-center"
                          style={{
                            backgroundImage: 'url(../Assets/images/whatsappBG.jpg)',
                            maxheight: '300px',
                            flexDirection: 'column',
                            margin: 'auto',
                            borderRadius: '5px',
                            backgroundColor: '#f5f5f5',
                          }}
                        >
                          <img src={`${selectedTemplate.file_img}`} alt="image preview" style={{ height: 'inherit', width: '100%', borderRadius: '10px' }}/>
                        </div>
                      )}

                      {selectedTemplate.t_type === 'Media' && selectedTemplate.media_type === 'Video' && (
                        <div
                          className="templateHeader d-flex justify-content-center align-items-center"
                          style={{
                            backgroundImage: 'url(../Assets/images/whatsappBG.jpg)',
                            maxheight: '300px',
                            flexDirection: 'column',
                            margin: 'auto',
                            borderRadius: '5px',
                            backgroundColor: '#f5f5f5',
                          }}
                        >
                          <video autoPlay className="uploaded-video viewMedia">
                            <source src={`${selectedTemplate.file_video}`} />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      )}

                      <p className="mt-3 mb-0">{selectedTemplate.message}</p>

                      {selectedTemplate.f_text && <p className="mb-0">{selectedTemplate.f_text}</p>}
                    </div>

                    <div className="showButtons d-flex align-items-center mt-3" style={{ flexDirection: 'column' }}>
                      {selectedTemplate.UrlBtnText1 && (
                        <button className="btn btn-primary mb-2">{selectedTemplate.UrlBtnText1}</button>
                      )}

                      {selectedTemplate.UrlBtnText2 && (
                        <button className="btn btn-primary mb-2">{selectedTemplate.UrlBtnText2}</button>
                      )}

                      {selectedTemplate.phone_text1 && (
                        <button className="btn btn-primary mb-2">{selectedTemplate.phone_text1}</button>
                      )}

                      {/* Render quick replies dynamically */}
                      {[...Array(10)].map((_, index) => {
                        const quickReplyKey = `quickReply${index + 1}`;
                        return (
                          selectedTemplate[quickReplyKey] && (
                            <button key={quickReplyKey} className="btn btn-primary mb-2">
                              {selectedTemplate[quickReplyKey]}
                            </button>
                          )
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <p>No template selected</p>
              )}
            </div>
          </div>
        </div>
      </div>
      
      {/* Snackbar for feedback messages */}
      <ShowSnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={snackBar.message}
        handleClose={handleCloseSnackBar}
      />

      {loading && (
        <div className="spinnerOverlay">
          <ClipLoader loading={loading} size={50} />
        </div>
      )}

    </>
  );
};

export default WbTemplate;
