import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import axios from "axios";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TablePagination from "@mui/material/TablePagination";
import { formatDate } from "../utils/helper";
import excelIcon from "../Assets/images/excel.png";
import closeIcon from "../Assets/images/close_icon.png";
import baseURL from '../config'
import ShowSnackBar from "../components/snackBar";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EuroIcon from '@mui/icons-material/Euro';


function Row({ row, onSubmit, index }) {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [walletAction, setWalletAction] = useState("Add");
  const [walletAmount, setWalletAmount] = useState("");
  const [walletRemarks, setWalletRemarks] = useState("");
  const [errors, setErrors] = useState("");
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const handleCloseSnackBar = (event, reason) => {
    setSnackBar((prevState) => ({ ...prevState, open: false }));
  };

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => {
    setWalletAction("Add");
    setWalletAmount("");
    setWalletRemarks("");
    setErrors({});
    setOpenModal(false);
  };

  const validateForm = () => {
    let formErrors = {};

    if (walletAmount.trim() === "") {
      formErrors.amount = "Amount is required.";
    }

    if (walletRemarks.trim() === "") {
      formErrors.remarks = "Remarks is required";
    }

    if (!row.currency_type && selectedCurrency.trim() === "") {
      formErrors.currency = "Currency is required.";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }
    onSubmit(walletAction, walletAmount, row.user_id, walletRemarks,selectedCurrency);
    handleClose();
  };

  const handleIndividualExcelExport = async(user_id) =>{
    try{
      const baseUrl = baseURL;
      const endpoint = `admin-transaction/getIndividualTransactionalExcelData?userId=${user_id}`;
      const url = `${baseUrl}/${endpoint}`;
      const user = JSON.parse(localStorage.getItem("user-cred"));

      const response = await axios.get(url, {
        responseType: 'blob',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'Authorization': `Bearer ${user?.token}`
            }
      });

      // Check for empty file
      if (response.data.size === 0) {
        throw new Error('Received empty file');
    }

    // Get filename from the response headers or use default
    const contentDisposition = response.headers['content-disposition'];
    let filename = `MIS_${user_id}_transactional_report.xlsx`;

    if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        if (filenameMatch && filenameMatch[1]) {
            filename = filenameMatch[1].replace(/['"]/g, '');
        }
    }

    // Create download link
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = downloadUrl;
    link.download = filename;

    // Append to document, click, and cleanup
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      setSnackBar({
        open: true,
        severity: true,
        message: 'MIS Report downloaded Successfully'
      })
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    }, 100);
      
    }catch(error){
      console.log('Error on downloading excel file: ', error);
    }
  }


  const [selectedCurrency, setSelectedCurrency] = useState("");  // Track selected currency

const handleCurrencyChange = (e) => {
  setSelectedCurrency(e.target.value);  // Update the currency when user selects a new option
};
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        {/* <TableCell>
        </TableCell> */}
        <TableCell
          align="right"
          className="position-relative"
          style={{ paddingLeft: "25px" }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            className="DropBtn"
            style={{ position: "absolute", left: 0, top: 0 }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <span>{row.user_id}</span>
        </TableCell>
        <TableCell align="right">{row.user_name}</TableCell>
        <TableCell align="right">
          {row?.balance ? parseFloat(row.balance).toFixed(3) : "N/A"}
        </TableCell>
        <TableCell align="right">
          {" "}
          <button onClick={handleOpen} className="eyeBtn">
          {row.currency_type === "Euro" ? (
  <EuroIcon style={{ fontSize: 20 }} />
) : row.currency_type === "Dollar" ? (
  <AttachMoneyIcon style={{ fontSize: 20 }} />
) : row.currency_type === "Rupees" ? (
  <CurrencyRupeeIcon style={{ fontSize: 20 }} />
) : (
  <>ADD</> 
)}
          
            {/* <p>{row.currency_type}</p> */}
            {/* <CurrencyRupeeIcon /> */}
          </button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {/* <Typography variant="h6" gutterBottom component="div">
                History
              </Typography> */}
              <Table size="small" aria-label="purchases" className="HTable ">
                <TableHead>
                  <TableRow>
                    <TableCell>History</TableCell>
                    <TableCell align="right">
                      User ID : <span>{row.user_id}</span>
                    </TableCell>
                    <TableCell align="right" colSpan={2}>
                      Opening Balance :{" "}
              
                      {row.currency_type === "Euro" ? (
            <EuroIcon style={{ fontSize: "16px" }} />
          ) : row.currency_type === "Dollar" ? (
         <AttachMoneyIcon style={{ fontSize: "16px" }} />
         ) : row.currency_type === "Rupees" ? (
          <CurrencyRupeeIcon style={{ fontSize: "16px" }} />
              ) : (
    <></> // If currency type is empty or unknown, render nothing or a default icon
              )}
                      
                      <span>
                        {row?.balance
                          ? parseFloat(row.balance).toFixed(3)
                          : "N/A"}
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      {/* <button className="ExportBtn">
          Export <img src={Export} alt="img" /> 
          </button> */}

                        

                      <button type="button" className="ExcelIconContain" onClick={() => handleIndividualExcelExport(row.user_id)}>
                        <img
                          src={excelIcon}
                          alt="excelIcon"
                          className="ExcelIcon"
                        />
                      </button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Admin ID</TableCell>
                    <TableCell align="right">Transaction Type</TableCell>
                    <TableCell align="right">Remarks</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history && row.history.length > 0 ? (
                    row.history.map((historyRow) => (
                      <React.Fragment key={historyRow.row_id}>
                        <TableRow>
                          <TableCell>{formatDate(historyRow?.date)}</TableCell>
                          <TableCell align="right">
                            {parseFloat(historyRow.amount).toFixed(3)}
                          </TableCell>
                          <TableCell align="right">
                            {historyRow.admin_id}
                          </TableCell>
                          <TableCell align="right">{historyRow.type}</TableCell>
                          <TableCell align="right">
                            <textarea
                              name="remarks"
                              id="hremarks"
                              className="TableRemarks"
                              readOnly
                              value={historyRow.remark}
                            />
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box className="ModalBox">
          <h2 className="WalletTitle">
            Wallet{" "}
            <button className="CloseBtn" onClick={handleClose}>
              <img src={closeIcon} alt="img" />
            </button>
          </h2>
          <Box className="WalletContain">
            <h5>
              User ID: <span className="UserID">{row.user_id}</span>
            </h5>
            <div className="Devider"></div>
            <div className="WalletSelect">
              <select
                name="Add"
                id="add"
                value={walletAction}
                onChange={(e) => setWalletAction(e.target.value)}
              >
                <option value="Add">Add</option>
                <option value="Deduct">Deduct</option>
              </select>
            
            </div>
            <div className="Devider"></div>

   
                    {/* Currency Type Selection */}
                    <div className="Currencytype">
        <label>Currency  <span style={{ color: "red", fontSize: "12px" }}>
                  *
                </span></label>
        {row.currency_type ? (
          // Display the currency type as a non-editable field
          <Typography>{row.currency_type}</Typography>
        ) : (
          // If no currency type exists, show the dropdown
          // <select value={selectedCurrency} onChange={handleCurrencyChange}>
          //   <option value="">---Select Currency Type ---</option>
          //   <option value="Rupees">Rupees</option>
          //   <option value="Euro">Euro</option>
          //   <option value="Dollar">Dollar</option>
          // </select>
          <div className="WalletSelect">
          <select
           
            value={selectedCurrency} onChange={handleCurrencyChange}
          >
             <option value="">---Select Currency Type ---</option>
           <option value="Rupees">Rupees</option>
           <option value="Euro">Euro</option>
            <option value="Dollar">Dollar</option>
          </select>
        
        </div>
        )}

        {errors.currency && (
          <Typography color="error" className="ErrorMsg">
            {errors.currency}
          </Typography>
        )}
      </div>


            <div className="Devider"></div>
          
            <div className="WalletAmount">
              <span style={{ position: "relative" }} className="d-inline-block">
                Balance{" "}
                <span style={{ color: "red" }} className="RequiredStar">
                  *
                </span>
              </span>
           {/* Render the selected currency icon */}
           {row.currency_type ? (
    row.currency_type === "Euro" ? (
      <EuroIcon style={{ fontSize: 20 }} />
    ) : row.currency_type === "Dollar" ? (
      <AttachMoneyIcon style={{ fontSize: 20 }} />
    ) : row.currency_type === "Rupees" ? (
      <CurrencyRupeeIcon style={{ fontSize: 20 }} />
    ) : null
  ) : (
    selectedCurrency === "Euro" ? (
      <EuroIcon style={{ fontSize: 20 }} />
    ) : selectedCurrency === "Dollar" ? (
      <AttachMoneyIcon style={{ fontSize: 20 }} />
    ) : selectedCurrency === "Rupees" ? (
      <CurrencyRupeeIcon style={{ fontSize: 20 }} />
    ) : null
  )}

              <input
                type="number"
                value={walletAmount}
                onChange={(e) => setWalletAmount(e.target.value)}
              />
              {errors.amount && (
                <Typography color="error" className="Errormsg">
                  {errors.amount}
                </Typography>
              )}
            </div>
            <div className="Devider"></div>
            <div className="WalletRemarks">
              <textarea
                name="remarks"
                id="remarks"
                value={walletRemarks}
                onChange={(e) => setWalletRemarks(e.target.value)}
                placeholder="Write your Remarks"
              ></textarea>
              {errors.remarks && (
                <Typography color="error" className="Errormsg">
                  {errors.remarks}
                </Typography>
              )}
            </div>
            <div className="Devider"></div>

            <div className="WalletBtns">
              <Button variant="contained" onClick={handleSubmit}>
                Proceed
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>
      
      <ShowSnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={snackBar.message}
        onClose={handleCloseSnackBar}
      />
    </React.Fragment>
  );
}

export default function CollapsibleTable({
  rows,
  totalRows,
  onSubmit,
  fetchTransactions,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchTransactions(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchTransactions(1, parseInt(event.target.value, 10));
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" className="Table">
        <TableHead>
          <TableRow>
            {/* <TableCell /> */}
            <TableCell align="right">User Id</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Wallet</TableCell>
            <TableCell align="right">Add</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Change: Render rows dynamically */}
          {rows.map((row, index) => (
            <Row key={row.id} row={row} onSubmit={onSubmit} index={index} />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={totalRows}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

CollapsibleTable.propTypes = {
  rows: PropTypes.array.isRequired,
  totalRows: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fetchTransactions: PropTypes.func.isRequired,
};
